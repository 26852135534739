<template>
    <div style="height: auto">
        <div style="width: 100%; overflow: hidden; text-align: left" v-if="getAdvData(6).children">
            <img :src="getAdvData(6).children[0].image" class="advImg" />
            <p style="font-size: 40px; position: absolute; z-index: 99; left: 160px; top: 80px">威有智能云解决方案</p>
            <p style="font-size: 14px; position: absolute; z-index: 99; left: 160px; top: 150px">
                深入制造、能源、水务、城市、金融等行业，打造全场景应用，助力企业数字化转型与智能化升级
            </p>
            <el-button class="shoqian" type="primary" @click="open(getAdvData(6).children[0].url)">
                <!-- <a :href="" target="_blank" rel="noopener noreferrer"> -->
                售前咨询
                <span :style="`background-image: url(${getWebData().public_phone});`"></span>
                <!-- </a> -->
            </el-button>
        </div>
        <div style="width: 100%; height: 250px; margin-top: -260px">
            <div class="divBoxs">
                <div class="divBox divBoxLeft" v-if="list.length > 0">
                    <img :src="list[0].bg" />
                    <div>
                        <p>
                            <span style="font-size: 18px">{{ list[0].title }}</span>
                            <span class="tag">{{ list[0].tag }}</span>
                        </p>
                        <div class="intro">{{ list[0].intro }}</div>
                        <button>了解详情</button>
                    </div>
                </div>
                <div class="divBox divBoxCenter" v-if="list.length > 1">
                    <div v-for="el in list.slice(1, 4)" :key="el.id">
                        <div>
                            <span class="bananrTitle">{{ el.title }}</span>
                            <span class="arrow-right">></span>
                        </div>
                        <div style="font-size: 14px">{{ el.intro }}</div>
                        <span class="tag">{{ el.tag }}</span>
                    </div>
                </div>
                <div class="divBox divBoxRight" v-if="list.length > 3">
                    <div v-for="el in list.slice(3, 6)" :key="el.id">
                        <div class="dowBox">
                            <span class="rightTitle">{{ el.title }}</span>
                            <el-button class="dowBtn" type="text">
                                <span class="tag">{{ el.tag }}</span>
                                <span class="arrow-right">></span>
                            </el-button>
                        </div>
                        <p>{{ el.intro }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="alljj">全部解决方案</div>
        <contentBox />
        <div class="footer">
            <div>
                <div>
                    <p style="font-size: 27px">立即联系你的专属顾问</p>
                    <p style="margin: 20px 0">免费咨询威有智能云专属顾问，为您量身定制产品推荐方案</p>
                    <button class="btn">
                        立即咨询
                        <span :style="`background-image: url(${getWebData().public_phone});`"></span>
                    </button>
                </div>
                <video
                    preload="auto"
                    width="100%"
                    style="z-index: 10; margin-bottom: -4px"
                    autoplay="autoplay"
                    muted="muted"
                    src="http://iv.vu818.com/video/videotest.mp4"
                    poster=""
                    loop=""
                ></video>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import contentBox from "./contentBox.vue";
export default {
    name: "Solution",
    inject: ["getWebData", "getAdvData"],
    components: { contentBox },
    data() {
        return {
            list: [],
        };
    },
    async created() {
        let { data } = await axios.get("/api/solution/shopshow");
        this.list = data;
    },
    methods: {
        open(url) {
            window.open(url, "_blank");
        },
    },
};
</script>
<style scoped lang="less">
.shoqian {
    background: #7781f1;
    padding: 15px 50px;
    position: absolute;
    z-index: 99;
    left: 160px;
    top: 200px;
    border: none;
    a {
        display: block;
        width: 100%;
        height: 100%;
        color: white;
    }
    &:hover {
        span {
            display: block;
            position: absolute;
            width: 100px;
            height: 100px;
            left: 50%;
            transform: translateX(-50%);
            top: -110px;

            background-repeat: no-repeat;
            background-size: 100% 100%;
            border-radius: 20px;
        }
    }
}
/deep/.el-input > input {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgb(117, 117, 117);
    background: none;
}
/deep/.el-input > input[type="text"]::placeholder {
    color: rgb(117, 117, 117);
}

.divBoxs {
    width: 81%;
    margin: 0 auto;
    height: 250px;
    box-shadow: 0 4px 16px 0 rgba(218, 221, 231, 0.7);
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    .divBoxLeft {
        position: relative;
        overflow: hidden;
        & > div {
            position: absolute;
            color: #fff;
            margin: 10px 25px;
            text-align: left;
            display: flex;
            flex-direction: column;
            .tag {
                color: white;
                background-color: rgba(255, 255, 255, 0.2);
                margin-left: 10px;
            }
            .intro {
                width: 90%;
                font-size: 14px;
                margin-top: 15px;
                line-height: 1.8;
            }
            p {
                display: flex;
                align-items: center;
            }
            button {
                margin-top: 20px;
                width: 92px;
                height: 32px;
                background: #fff;
                border-radius: 5px;
                border: none;
            }
        }
        & > img {
            position: absolute;
            left: 0;
            top: 0;
            transition: 0.3s ease-out;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:hover {
            & > img {
                transform: scale(1.1);
            }
        }
    }
    .divBoxCenter {
        display: flex;
        flex-direction: column;
        border-right: 1px solid rgba(34, 34, 34, 0.08);
        & > div:nth-of-type(1) {
            border-bottom: 1px solid rgba(34, 34, 34, 0.08);
        }
        & > div {
            position: relative;
            height: 50%;
            background-color: white;
            text-align: left;
            padding: 20px;
            & > div:nth-of-type(1) {
                font-weight: bold;
                cursor: pointer;
                .arrow-right {
                    transition: 0.5s;
                }
                &:hover {
                    color: #7781f1;
                    .arrow-right {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .divBoxRight {
        position: relative;
        & > div {
            width: 100%;
            height: 33.3%;
            position: relative;
            padding-left: 10px;
            padding-top: 10px;
            .dowBox {
                display: flex;
                align-items: center;
                text-align: left;
                color: black;
                position: relative;
                .rightTitle {
                    font-size: 18px;
                }
                .dowBtn {
                    color: black;
                    // margin-left: 40px;
                    font-weight: bold;
                    position: absolute;
                    right: 30px;
                    transition: 0.5s;
                }
            }
            .dowBox:hover {
                color: #7781f1 !important;
                .rightTitle {
                    font-size: 18px;
                    color: #7781f1 !important;
                }
                .dowBtn {
                    color: #7781f1 !important;
                    right: 20px;
                }
            }
            p {
                font-size: 14px;
                text-align: left;
                color: black;
            }
        }
    }
    .divBox {
        flex: 1;
        margin: 0 auto;
        display: inline-block;
        height: 250px;
        background: #fff;
    }
}

.alljj {
    font-size: 30px;
    color: black;
    margin-top: 50px;
    margin-bottom: 20px;
}
.footer {
    & > div {
        width: 100%;
        overflow: hidden;
        position: relative;
        & > div {
            width: 100%;
            position: absolute;
            z-index: 99;
            top: 100px;
            text-align: left;
            left: 69px;
            color: #fff;
        }
    }
    .btn {
        padding: 12px 20px;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        border: 1px solid transparent;
        position: relative;
        &:hover {
            color: #7781f1;
            & > span {
                display: block;
                width: 100px;
                height: 100px;
                position: absolute;
                top: -110px;
                left: 50%;
                transform: translateX(-50%);
                background-repeat: no-repeat;
                background-size: 100% 100%;
                border-radius: 20px;
            }
        }
    }
}
.advImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.tag {
    display: inline-block;
    background-color: #f4f4f5;
    color: #909399;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    padding: 0 6px;
    border-radius: 4px;
    text-align: center;
}
</style>
