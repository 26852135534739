<template>
    <!-- 全部解决方案 -->
    <div class="main">
        <div class="topNav">
            <!-- 骨架屏 -->
            <el-skeleton animated :loading="!list.length">
                <template slot="template">
                    <div class="top_nav" style="display: flex; justify-content: center; align-items: center">
                        <el-skeleton-item v-for="el in 3" :key="el" variant="text" style="width: 20%; height: 26px" />
                    </div>
                </template>
                <template>
                    <div class="top_nav" style="display: flex; justify-content: center; align-items: center">
                        <div
                            v-for="el in list"
                            :key="el.id"
                            :class="{ active: el.id == topActiveId }"
                            @click="topNavClick(el.id)"
                            v-show="el.status"
                        >
                            {{ el.title }}
                        </div>
                    </div>
                </template>
            </el-skeleton>
        </div>
        <div class="list">
            <!-- 20%left -->
            <div class="left">
                <div>
                    <!-- 骨架屏 -->
                    <el-skeleton animated :loading="!list.length">
                        <template slot="template">
                            <div class="left-item" v-for="el in 5" :key="el">
                                <el-skeleton-item variant="image" style="height: 40px; width: 40px; margin: 0 10px 0 20px" />
                                <el-skeleton-item variant="text" style="width: 50%" />
                            </div>
                        </template>
                        <template>
                            <div
                                v-for="el in twoList"
                                :key="el.id"
                                :class="{ active: el.id == leftActiveId }"
                                @click="twoClick(el.id)"
                                class="left-item"
                                v-show="el.status"
                            >
                                <img :src="el.icon" />
                                <span>{{ el.title }}</span>
                            </div>
                        </template>
                    </el-skeleton>
                </div>
            </div>
            <!-- 80%right -->
            <div class="right" ref="right">
                <!-- 骨架屏 -->
                <el-skeleton animated :loading="!list.length">
                    <template slot="template">
                        <div class="right-item" v-for="e in 5" :key="e">
                            <el-skeleton-item variant="h3" style="width: 30%" />
                            <el-skeleton-item variant="p" />
                            <div>
                                <div class="it" v-for="el in 6" :key="el">
                                    <div>
                                        <el-skeleton-item variant="text" style="width: 30%" />
                                        <el-skeleton-item variant="p" />
                                        <el-skeleton-item variant="p" />
                                        <el-skeleton-item variant="p" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template>
                        <div
                            v-for="el in twoList"
                            :key="el.id"
                            :id="'two' + el.id"
                            class="right-item"
                            :style="{ 'background-image': `url(${el.list_image})` }"
                        >
                            <h3>{{ el.title }}<el-icon class="el-icon-arrow-right"></el-icon></h3>
                            <p>{{ el.intro }}</p>
                            <div>
                                <div v-for="e in el.children" :key="e.id" class="it" v-show="e.status">
                                    <div @click="$router.push('/SolutionDetails?id=' + e.id)">
                                        <img :src="e.bg_image" class="bg" />
                                        <div class="icon-right">
                                            <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
                                        </div>
                                        <p>{{ e.title }}</p>
                                        <p>{{ e.intro }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            list: [],
            // 激活一级id
            topActiveId: null,
            // 激活二级id
            leftActiveId: null,
        };
    },
    async created() {
        this.list = await this.getData();
        this.topActiveId = this.list[0].id;
        window.addEventListener("scroll", this.scroll);
        this.$nextTick(() => {
            if (this.$route.query.id) {
                this.topNavClick(this.$route.query.pid);
                this.$nextTick(() => {
                    this.twoClick(this.$route.query.id);
                });
            }
        });
    },
    mounted() {
        // setTimeout(() => {
        //     if (this.$route.query.id) {
        //         this.topNavClick(this.$route.query.pid);
        //         this.$nextTick(() => {
        //             this.twoClick(this.$route.query.id);
        //         });
        //     }
        // }, 500);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.scroll);
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/solution/all");
            return data;
        },
        // 左侧二级列表点击
        twoClick(id) {
            let e = document.getElementById("two" + id);
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            window.scrollTo({
                top: e.getBoundingClientRect().top + scrollTop - 173,
                behavior: "smooth",
            });
        },
        // 滚动
        scroll() {
            if (this.$route.path != "/Solution") return;
            let el = this.$refs.right;
            let a = el.querySelectorAll(".right-item");
            let arr = []; // arr:[-,-,-,+,+,+] -负数+正数 每次滚动取第一个正数为当前激活的元素
            for (let i = 0; i < a.length; i++) {
                let count = a[i].offsetTop - window.pageYOffset - 173;
                arr.push(count);
            }
            let i = arr.findIndex(e => e >= 0);
            try {
                this.leftActiveId = this.twoList[i].id;
            } catch (error) {}
        },
        // 顶部导航点击
        topNavClick(id) {
            this.topActiveId = id;
            window.scrollTo({
                top: 200,
            });
        },
    },
    computed: {
        // 二级列表
        twoList() {
            let obj = this.list.find(e => e.id == this.topActiveId);
            return obj ? obj.children : [];
        },
    },
    watch: {
        "$route.query.id"() {
            this.$nextTick(() => {
                if (this.$route.query.id) {
                    this.topNavClick(this.$route.query.pid);
                    this.$nextTick(() => {
                        this.twoClick(this.$route.query.id);
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    .topNav {
        margin: 0 auto;
        width: 90%;
        padding: 30px 0 20px;
        font-size: 20px;

        position: sticky;
        top: 85px;
        z-index: 100;
        background-color: white;
        border-bottom: 1px solid rgba(218, 221, 231, 0.7);
        margin-bottom: 20px;
        // box-shadow: rgba(218, 221, 231, 0.7) 0px 4px 16px 0px;
        .top_nav {
            & > div {
                margin: 0 10px;
                cursor: pointer;
                position: relative;
            }
        }
        .active {
            &::after {
                content: " ";
                position: absolute;
                height: 1px;
                width: 100%;
                background-color: #7781f1;
                left: 0px;
                top: calc(100% + 20px);
            }
        }
    }
    .list {
        display: flex;
        .left {
            width: 20%;
            & > div {
                margin-left: 25%;
                width: 70%;
                position: sticky;
                top: 165px;
                min-height: 550px;
                background-color: rgb(245, 247, 250);
                padding: 10px 0;
                .left-item {
                    padding: 15px 0;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    position: relative;
                    height: 20px;
                    box-sizing: content-box;
                    cursor: pointer;
                    img {
                        max-height: 40px;
                        margin: 0 10px 0 20px;
                    }
                    &:hover {
                        background-color: white;
                        color: #7781f1;
                    }
                }
                .active {
                    background-color: white;
                    &::before {
                        content: " ";
                        position: absolute;
                        left: 0px;
                        top: 50%;
                        height: 20px;
                        width: 2px;
                        background-color: #7781f1;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .right {
            width: 80%;
            text-align: left;
            .right-item {
                width: 90%;
                color: black;
                padding: 10px;
                margin-top: 20px;
                background-position: 100% 0;
                background-repeat: no-repeat;
                background-size: 40% auto;
                & > h3 {
                    font-size: 20px;
                    font-weight: 100;
                    padding-bottom: 10px;
                    cursor: pointer;
                    .el-icon-arrow-right {
                        transition: 0.5s;
                    }
                    &:hover {
                        color: #7781f1;
                        .el-icon-arrow-right {
                            margin-left: 10px;
                        }
                    }
                }
                & > p {
                    font-size: 14px;
                }
                & > div {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    .it {
                        width: 33.3%;
                        padding: 15px 30px 15px 0;
                        & > div {
                            position: relative;
                            background: rgb(245, 247, 250);
                            padding: 20px;
                            transition: 0.5s;
                            cursor: pointer;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: rgba(34, 34, 34, 0.7);
                            height: 150px;
                            position: relative;
                            .icon-right {
                                transition: 0.5s;
                                transform: translateX(0px);
                                position: absolute;
                                top: 10px;
                                right: 20px;
                                height: 20px;
                                width: 26px;
                                overflow: hidden;
                                & > img {
                                    position: absolute;
                                    top: -105px;
                                    left: -1340px;
                                }
                            }
                            & > p:nth-of-type(1) {
                                font-size: 18px;
                                height: 28px;
                                line-height: 28px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            & > p:nth-of-type(2) {
                                font-size: 14px;
                                margin: 10px 0;
                                height: 72px;
                                line-height: 24px;
                                white-space: normal;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                            }
                            &:hover {
                                box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px 1px;
                                transform: translateY(-5px);
                                color: #7781f1;
                                .icon-right {
                                    transform: translateX(10px) !important;
                                }
                                .bg {
                                    opacity: 1;
                                }
                            }
                            .bg {
                                position: absolute;
                                height: 100%;
                                top: 0;
                                right: 0;
                                z-index: -1;
                                opacity: 0;
                                transition: 0.5s;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
